<template>
	<div class="animated fadeIn">
		<template v-if="$route.name !== 'SalesComparison'">
			<div class="row">
				<div class="col">
					<div
						:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
						class="btn mx-1 bg-primary-alt"
						@click="toggleFilters">
						<i
							class="fa fa-filter"
							aria-hidden="true" />
					</div>
					<div class="btn btn-lg">
						{{ datePeriod }}
					</div>
					<b-button
						:disabled="loading"
						class="float-right"
						variant="success"
						@click="downloadExcel">
						<i :class="!loading ? 'fas fa-file-excel' : 'fas fa-spinner fa-spin'" /> {{ translate('export') }}
					</b-button>
				</div>
			</div>
			<hr>
			<data-filter
				:display="showFilters"
				get-by="name"
				@submit="getDataFiltered"
				@clear="clearFilters">
				<template slot="form">
					<div class="row">
						<div class="col-sm">
							<div
								class="form-group">
								<label
									for="dateRange"
									class="label">{{ translate('date_range') }}</label>
								<select
									id="dateRange"
									v-model="dateRange"
									name="dateRange"
									class="form-control">
									<option
										v-for="dateRangeInfo in dateRanges"
										:key="dateRangeInfo"
										:value="dateRangeInfo">
										{{ translate(dateRangeInfo) }}
									</option>
								</select>
							</div>
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							class="col-sm">
							<div
								class="form-group">
								<label
									for="startDate"
									class="label">{{ translate('start_date') }}</label>
								<v-date-picker
									id="startDate"
									v-model="startDate"
									:input-props="{ class: 'form-control' }"
									:formats="calendarConfig"
									name="startDate"
									show-caps />
							</div>
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							class="col-sm">
							<div
								class="form-group">
								<label
									for="endDate"
									class="label">{{ translate('end_date') }}</label>
								<v-date-picker
									id="endate"
									v-model="endDate"
									:input-props="{ class: 'form-control' }"
									:formats="calendarConfig"
									name="endDate"
									show-caps />
							</div>
						</div>
						<div
							v-if="$route.name === 'SalesState' && dateRange === dateRanges.custom"
							class="col-sm-12" />
						<div
							v-if="(($route.name === 'SalesProducts' && dateRange !== dateRanges.custom ) || $route.name === 'SalesState' || $route.name === 'SalesPaymentMethod') && $user.details().country === undefined"
							class="col-sm">
							<div class="form-group">
								<label
									for="countryName"
									class="label">{{ translate('country') }}</label>

								<select
									id="countryName"
									v-model="filters.country"
									name="country"
									class="form-control">
									<option
										v-if="$route.name === 'SalesState'"
										:value="'all'">
										{{ translate('all') }}
									</option>
									<option
										v-else
										:value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="country in countries"
										:key="country.attributes.code"
										:value="country.attributes.code">
										{{ translate(country.attributes.code.toLowerCase()) }}
									</option>
								</select>
							</div>
						</div>
						<div
							v-if="($route.name !== 'SalesProducts' && $route.name !== 'SalesPaymentMethod') || ($route.name === 'SalesProducts' && dateRange !== dateRanges.custom)"
							class="col-sm">
							<div class="form-group">
								<label
									for="method"
									class="label">{{ translate('method') }}</label>

								<select
									id="payment_method"
									v-model="filters.payment_method"
									name="payment_method"
									class="form-control">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="method in methods"
										:key="method.attributes.method_code_name"
										:value="method.attributes.method_code_name">
										{{ translate(`sales_${method.attributes.method_code_name.toLowerCase()}`) }}
									</option>
								</select>
							</div>
						</div>
						<div
							v-if="$route.name === 'SalesState' && dateRange === dateRanges.custom"
							class="col-sm" />
					</div>
					<div
						v-if="$route.name === 'SalesProducts' && dateRange !== dateRanges.custom"
						class="row">
						<div class="col-sm-4 col-xs-12">
							<div class="form-group">
								<label
									for="method"
									class="label">{{ translate('product_group') }}</label>
								<select
									id="product_group"
									v-model="filters.product_group"
									name="product_group"
									class="form-control">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="group in productGroups"
										:key="group.attributes.code_name"
										:value="group.attributes.code_name">
										{{ translate(group.attributes.code_name) }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-sm">
							<div
								id="formSelector"
								class="form-group">
								<label
									for="products"
									class="label">{{ translate('product') }}</label>
								<multiselect
									id="product"
									ref="productRef"
									v-model="msProducts"
									:options="products"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									:placeholder="translate('products')"
									label="text"
									:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
									track-by="value"
									name="products">
									<template
										slot="selection" />
									<template #option="{option}">
										{{ option.text }}
									</template>
								</multiselect>
							</div>
						</div>
					</div>
					<div
						v-if="$route.name === 'SalesProducts' && dateRange === dateRanges.custom && $user.details().country === undefined"
						class="row">
						<div class="col-sm">
							<div class="form-group">
								<label
									for="countryName"
									class="label">{{ translate('country') }}</label>

								<select
									id="countryName"
									v-model="filters.country"
									name="country"
									class="form-control">
									<option
										v-if="$route.name === 'SalesState'"
										:value="'all'">
										{{ translate('all') }}
									</option>
									<option
										v-else
										:value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="country in countries"
										:key="country.attributes.code"
										:value="country.attributes.code">
										{{ translate(country.attributes.code.toLowerCase()) }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-sm">
							<div class="form-group">
								<label
									for="method"
									class="label">{{ translate('method') }}</label>

								<select
									id="payment_method"
									v-model="filters.payment_method"
									name="payment_method"
									class="form-control">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="method in methods"
										:key="method.attributes.method_code_name"
										:value="method.attributes.method_code_name">
										{{ translate(method.attributes.method_code_name.toLowerCase()) }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-sm">
							<div class="form-group">
								<label
									for="method"
									class="label">{{ translate('product_group') }}</label>
								<select
									id="product_group"
									v-model="filters.product_group"
									name="product_group"
									class="form-control">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="group in productGroups"
										:key="group.attributes.code_name"
										:value="group.attributes.code_name">
										{{ translate(group.attributes.code_name) }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-sm-12 col-xs-12">
							<div
								id="formSelector"
								class="form-group">
								<label
									for="products"
									class="label">{{ translate('product') }}</label>
								<multiselect
									id="product"
									v-model="msProducts"
									:options="products"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									:placeholder="translate('products')"
									label="text"
									:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
									track-by="value"
									name="products">
									<template
										slot="selection" />
									<template #option="{option}">
										{{ option.text }}
									</template>
								</multiselect>
							</div>
						</div>
					</div>
				</template>
			</data-filter>
		</template>

		<!-- <b-row class="mb-4">
			<b-col
				sm="12"
				md="6">
				<b-card
					no-body
					class="bg-white h-100">
					<is-loading
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')"
						:loading="loading"
						:has-data="hasData" />
					<div
						v-if="!loading && hasData"
						class="card-body mb-0 pb-0">
						<div class="row">
							<div class="col-11">
								<div
									v-for="(amount, method) in data.payment_methods"
									:key="method"
									class="row">
									<div class="col-6 font-weight-bold">{{ translate(method) }}</div>
									<div class="col-6 text-right">{{ amount }}</div>
								</div>
							</div>
							<div class="col-1 p-0">
								<div class="h1 text-muted text-right mb-4">
									<i class="fas fa-coins" />
								</div>
							</div>
						</div>
						<div class="row h4 mb-0">
							<div class="col">{{ translate('total') }}</div>
							<div class="col text-right">{{ data.grand_total }}</div>
						</div>
					</div>
					<b-progress
						:value="100"
						class="progress-xs ml-3 mr-3 custom-progress" />
					<div class="clearfix mb-3" />
				</b-card>
			</b-col>
			<b-col
				sm="12"
				md="6">
				<b-card
					no-body
					class="bg-white h-100">
					<is-loading
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')"
						:loading="loading"
						:has-data="hasData" />
					<div
						v-if="!loading && hasData"
						class="card-body mb-0 pb-0">
						<div class="row">
							<div class="col col-11">
								<div class="row">
									<div class="col-6 font-weight-bold">{{ translate('verified_sales') }}</div>
									<div class="col-6 text-right">{{ data.verified_orders.amount }} ({{ data.verified_orders.percentage }})</div>
								</div>
							</div>
							<div class="col col-1 p-0">
								<div class="h1 text-muted text-right mb-4">
									<i class="fas fa-cash-register" />
								</div>
							</div>
						</div>
						<div class="row h4 mb-0">
							<div class="col">{{ translate('total_orders') }}</div>
							<div class="col text-right">{{ data.orders.amount }}</div>
						</div>
					</div>
					<b-progress
						:value="100"
						class="progress-xs ml-3 mr-3 custom-progress" />
					<div class="clearfix mb-3" />
				</b-card>
			</b-col>
		</b-row> -->
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template slot="title">
							<router-link
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name, query }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
						<template
							v-if="tabIndex === index"
							class="p-0">
							<router-view />
						</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import Multiselect from 'vue-multiselect';
import {
	Report,
	SalesPaymentMethods as methodsMessages,
	Countries,
	Products as productMessages,
	Purchase,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import {
	SALES_PERIOD_FILTERS as dateRanges,
	ALL_TIME_PERIOD_FILTERS as allTimeDateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	DATE_START_FILTER as dateStartFilter,
	DATE_END_FILTER as dateEndFilter,
	allTime,
	custom,
} from '@/settings/Dates';
import { DEFAULT_COUNTRY, PICKUP_STORE } from '@/settings/Country';
import DataFilter from '@/components/DataFilter';
import Sales from '@/util/Report';
import Country from '@/util/Country';
import { FREEPRODUCTPREFIX } from '@/settings/Products';
import Products from '@/util/Products';
import PaymentMethods from '@/util/PaymentMethods';
import 'vue-multiselect/dist/vue-multiselect.min.css';

const countries = new Country();
const products = new Products();
const productGroups = new Products();
const methods = new PaymentMethods();

export default {
	name: 'Sales',
	messages: [Report, methodsMessages, Countries, productMessages, Purchase],
	components: { DataFilter, Multiselect },
	mixins: [FiltersParams],
	data() {
		return {
			dateRanges,
			allTimeDateRanges,
			allTime,
			custom,
			calendarConfig,
			loading: false,
			sales: new Sales(),
			dateRange: dateRanges.today,
			rangeStart: '',
			rangeEnd: '',
			tabIndex: 0,
			freePrefix: FREEPRODUCTPREFIX,
			productsOnly: [],
			tabs: [
				{
					name: 'SalesCountry',
					translate_key: 'countries',
				},
				{
					name: 'SalesContinent',
					translate_key: 'continents',
				},
				{
					name: 'SalesPeriods',
					translate_key: 'period',
				},
				{
					name: 'SalesPaymentMethod',
					translate_key: 'payment_methods',
				},
				{
					name: 'SalesState',
					translate_key: 'states',
				},
				{
					name: 'SalesProducts',
					translate_key: 'products',
				},
				{
					name: 'SalesComparison',
					translate_key: 'comparison',
				},
			],
			countries: [],
			products: [],
			productGroups: [],
			methods: [],
			msProducts: [],
		};
	},
	computed: {
		query() {
			const tempQuery = this.$route.query;
			if (this.$route.name !== 'SalesProducts') {
				delete tempQuery.products;
			} else {
				this.closeMultiselect();
			}
			return tempQuery;
		},
		datePeriod() {
			return this.getDatePeriod();
		},
	},
	watch: {
		msProducts: function productWatch() {
			const productsSelect = this.msProducts;
			if (this.handleSelectAll(productsSelect, 'products') === true && productsSelect !== this.productsOnly) {
				this.msProducts = this.productsOnly;
			}
		},
		language() {
			if (this.msProducts.length > 0) {
				this.msProducts = this.msProducts.map((item) => ({
					value: item.value,
					text: this.translate(item.value.toLowerCase()),
				}));
			}
			this.setMultiselect(this.msProducts);
		},
	},
	mounted() {
		if (this.$route.name === 'SalesProducts') {
			countries.getReportCountries({ include_blacklist: true }).then((response) => {
				this.countries = response;
			});
			productGroups.getProductGroups().then((response) => {
				this.productGroups = response;
			});
			this.setMultiselect(this.filters.products);
		} else if (this.$route.name === 'SalesState' || this.$route.name === 'SalesPaymentMethod') {
			countries.getReportCountries({ include_blacklist: this.$route.name === 'SalesPaymentMethod' }).then((response) => {
				this.countries = response;
			});
		}

		if (this.$route.name === 'SalesState' && (typeof this.$route.query.country === 'undefined' || this.$route.query.country === PICKUP_STORE)) this.filters.country = DEFAULT_COUNTRY;

		if (this.$route.name === 'SalesPeriods') {
			delete this.dateRanges.all_time;
			delete this.dateRanges.custom;
			this.dateRanges = { ...this.dateRanges, ...this.allTimeDateRanges };
			this.dateRanges.custom = this.custom;
		} else {
			this.dateRanges.all_time = this.allTime;
			this.dateRanges.custom = this.custom;
		}
		if (typeof this.$route.name !== 'undefined') {
			this.tabIndex = this.tabs.findIndex((tabInfo) => tabInfo.name === this.$route.name);
		}
		methods.getAllPaymentMethods().then((response) => {
			this.methods = response;
		});
		const { query } = this.$route;
		const dateKeys = Object.keys(query);
		if (typeof this.dateRanges[query.dateRange] !== 'undefined') {
			this.dateRange = query.dateRange;
		} else if (typeof query.dateRange !== 'undefined' && !Object.values(this.dateRanges).includes(query.dateRange)) {
			if (['SalesPeriods', 'SalesCountry', 'SalesProducts', 'SalesContinent'].includes(this.$route.name)) {
				this.dateRange = this.dateRanges.today;
				query.dateRange = this.dateRanges.today;
			}
		} else if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
			this.dateRange = this.dateRanges.custom;
		} else if (typeof query.dateRange === 'undefined') {
			if (['SalesPeriods', 'SalesCountry', 'SalesProducts', 'SalesState', 'SalesContinent'].includes(this.$route.name)) {
				this.dateRange = this.dateRanges.today;
			}
		}
		return null;
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			if (this.msProducts.length > 0) {
				this.filters.products = [];
				this.msProducts.forEach((product) => {
					this.filters.products.push(product.value);
					this.filters.products.push(`${this.freePrefix}${product.value}`);
				});
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options });
		},
		closeMultiselect() {
			setTimeout(() => { // close first multiselect on showing filters
				if (typeof this.$refs.productRef !== 'undefined') {
					this.$refs.productRef.toggle();
				}
			}, 1);
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		downloadExcel() {
			const options = { ...this.query };
			const totals = new Sales();
			this.loading = true;

			totals.downloadSalesReport(options).then(() => {
				const { response } = totals.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.datePeriod} - ${this.translate('sales_report_name')}.xlsx`);
				this.$el.appendChild(link);
				link.click();
				this.loading = false;
			}).catch(() => {
				this.loading = false;
			});
		},
		setMultiselect(selectValue) {
			products.getAllProducts().then((response) => {
				this.products = response.map((item) => ({
					value: item.attributes.sku,
					text: item.attributes.name,
				}));
				this.productsOnly = [...this.products];
				this.products.unshift({ value: 'ALL', text: this.translate('all_products') });
				this.msProducts = this.searchSelectValues(selectValue, this.productsOnly);
			});
		},
		toggleFilters() {
			this.showFilters = !this.showFilters;
			if (this.showFilters) {
				if (this.$route.name === 'SalesProducts') {
					this.closeMultiselect();
				}
			}
		},
		searchSelectValues(selectValue, searchIn) {
			let values = [];
			let item;
			let selection = selectValue;

			if (typeof selectValue === 'undefined') {
				return '';
			}

			if (typeof selectValue === 'string') {
				selection = [selectValue];
			}

			if (typeof selectValue[0] !== 'string') {
				return selectValue;
			}
			const arrValues = selection;
			arrValues.forEach((value) => {
				item = searchIn.find((option) => option.value === value);
				if (typeof item !== 'undefined') values.push(item);
			});

			if (values.length === 0) {
				values = [];
			}

			return values;
		},
		handleSelectAll(select, field) {
			let allSelected = false;
			if (typeof select[0] === 'undefined') return false;

			if (field === 'products') {
				const selectedProducts = select.map((product) => product.value);
				const numberOfProducts = this.productsOnly.length;
				const selectAllExists = (this.products[0].value === 'ALL');

				if (selectedProducts.includes('ALL') || selectedProducts.length === numberOfProducts) {
					if (selectAllExists) {
						this.products.shift();
					}
					allSelected = true;
				}
				if ((!selectAllExists) && (selectedProducts.length !== numberOfProducts)) {
					this.products.unshift({ value: 'ALL', text: this.translate('all_countries') });
				}
			}
			return allSelected;
		},
	},
};
</script>
